module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Peak Performance Chiropractic","short_name":"Peak Performance","start_url":"/","background_color":"#FFFFFF","theme_color":"#60C5BA","display":"standalone","icon":"src/assets/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"dd87f6bc52c2083fecbd1419e558ed93"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
